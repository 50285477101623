import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1423 1864 c-35 -7 -77 -21 -95 -30 -44 -22 -98 -74 -98 -95 0 -19
47 -69 65 -69 7 0 15 -10 19 -22 3 -13 7 -25 8 -28 2 -2 -10 -4 -27 -5 -23 0
-33 -7 -45 -30 -16 -33 -24 -165 -9 -165 5 0 9 -9 9 -20 0 -51 83 -143 133
-148 4 -1 15 -8 23 -17 13 -12 14 -19 4 -30 -15 -18 -46 -20 -56 -4 -4 7 -27
9 -68 5 -33 -3 -68 -6 -76 -5 -8 0 -46 2 -85 3 -38 2 -91 6 -117 11 -58 9
-110 -7 -138 -42 -17 -22 -20 -41 -20 -119 0 -51 5 -104 11 -118 19 -40 66
-59 134 -53 33 4 65 6 70 6 6 0 154 0 330 0 176 0 331 0 345 0 14 0 98 -1 187
-2 l163 -2 27 28 c28 27 28 29 25 135 -2 105 -3 108 -33 135 l-30 27 -187 -1
c-103 -1 -209 2 -235 5 -39 6 -55 3 -84 -14 -27 -16 -38 -18 -49 -9 -23 19
-16 29 20 29 19 0 38 5 41 10 4 6 10 8 15 5 4 -3 13 2 20 10 7 8 18 15 25 15
24 0 91 85 110 140 1 3 5 13 9 23 5 9 7 48 4 85 -4 77 -30 119 -67 109 -46
-12 -28 22 42 80 15 13 27 30 27 39 0 49 -96 114 -190 129 -84 13 -95 13 -157
-1z m185 -32 c32 -11 80 -34 107 -52 l47 -32 -31 -31 -30 -31 -23 21 c-38 35
-88 55 -149 60 -72 7 -136 -10 -191 -49 l-40 -30 -28 27 -29 27 27 23 c94 78
222 103 340 67z m-42 -107 c27 -8 64 -25 82 -38 31 -23 31 -25 13 -41 -17 -15
-21 -15 -62 9 -32 18 -59 25 -99 25 -40 0 -67 -7 -99 -25 -41 -24 -45 -24 -62
-9 -18 16 -17 17 8 37 29 23 116 56 149 57 11 0 43 -7 70 -15z m0 -94 c101
-46 120 -172 39 -251 -31 -30 -43 -35 -99 -38 -62 -4 -65 -3 -104 33 -67 59
-77 129 -32 203 22 35 88 72 130 72 14 0 44 -9 66 -19z m-254 -119 c-3 -55 -1
-76 16 -108 35 -70 93 -104 174 -104 64 0 111 23 153 74 28 35 30 44 31 122 0
65 4 88 17 101 15 15 17 14 28 -13 7 -16 13 -58 13 -94 2 -220 -262 -330 -418
-174 -49 49 -70 102 -69 174 0 36 5 78 12 94 11 27 13 28 30 12 15 -14 18 -27
13 -84z m-278 -342 c26 -9 26 -11 26 -118 0 -71 -4 -112 -12 -120 -16 -16
-130 -16 -146 0 -8 8 -12 48 -12 114 0 95 2 104 22 118 25 18 84 21 122 6z
m316 -120 l0 -130 -35 0 -35 0 0 105 c0 58 -4 105 -9 105 -16 0 -21 -30 -21
-121 l0 -89 -35 0 -34 0 -3 102 c-2 70 -7 103 -15 106 -10 3 -13 -22 -13 -102
l0 -106 -35 0 -35 0 0 125 0 125 48 0 c27 0 57 -6 68 -14 14 -11 22 -12 33 -3
7 7 37 15 65 18 28 3 52 7 54 8 1 0 2 -57 2 -129z m352 118 c9 -7 18 -24 20
-38 2 -21 -2 -26 -23 -28 -20 -2 -28 2 -32 17 -6 25 -25 27 -34 4 -4 -10 2
-23 18 -35 73 -58 79 -66 79 -105 0 -52 -14 -63 -78 -63 -69 0 -92 12 -92 50
0 27 3 30 30 30 23 0 30 -4 30 -20 0 -13 7 -20 19 -20 38 0 32 21 -18 67 -46
42 -51 50 -51 90 0 36 4 45 23 53 33 13 91 12 109 -2z m118 -105 c0 -79 3
-104 13 -100 8 3 12 33 12 105 l0 102 38 0 37 0 0 -109 c0 -133 -5 -141 -89
-141 -31 0 -62 5 -69 12 -8 8 -12 51 -12 128 l0 116 35 -4 35 -4 0 -105z
m-298 32 c10 -38 18 -94 18 -122 l0 -53 -35 0 c-31 0 -35 3 -35 25 0 16 -6 25
-15 25 -9 0 -15 -9 -15 -25 0 -22 -4 -25 -36 -25 l-37 0 7 68 c4 37 14 93 23
125 l16 58 45 -3 46 -3 18 -70z m562 69 c18 -7 23 -89 6 -99 -6 -4 -4 -12 5
-21 9 -9 15 -34 15 -60 0 -39 -3 -46 -26 -54 -14 -6 -52 -10 -85 -10 l-59 0 0
125 0 125 64 0 c35 0 71 -3 80 -6z"/>
<path d="M966 1133 c-3 -3 -6 -44 -6 -90 0 -67 3 -83 15 -83 12 0 15 16 15 84
0 78 -7 105 -24 89z"/>
<path d="M1450 1120 c0 -14 -3 -42 -6 -62 -5 -31 -4 -38 10 -38 13 0 16 10 16
48 0 26 -5 54 -10 62 -8 12 -10 10 -10 -10z"/>
<path d="M2010 1105 c0 -16 6 -25 15 -25 9 0 15 9 15 25 0 16 -6 25 -15 25 -9
0 -15 -9 -15 -25z"/>
<path d="M2010 994 c0 -45 24 -42 28 4 2 24 -1 32 -12 32 -12 0 -16 -10 -16
-36z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
